button {
  all: unset;
}

.section-cart {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-cart {
  width: 800px;
  box-shadow: none;
}

.title1 {
  color: black;
  font-size: 18px;
  font-weight: bolder;
}

.title2 {
  color: black;
  font-size: 16px;
  font-weight: bolder;
}

.title2:hover {
  color: black;
  cursor: default;
}

.date-purchase {
  width: 35%;
  display: flex;
  flex-direction: column;
  text-align: center;
  border-right: 1px solid #efefef;
  justify-content: center;
}

.description-purchase {
  width: 65%;
  display: flex;
  padding-left: 10px;
}

.div-data-purchase {
  width: 60%;
  display: flex;
  flex-direction: column;
}

.div-count-purchase {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.count-purchase {
  background-color: #d3a172;
  width: 25px;
  height: 25px;
  margin: 0 10px;
  font-size: 15px;
  color: white;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.count-purchase:hover {
  background-color: #26a47b;
}

.count-purchase-disabled {
  background-color: rgb(173, 173, 173) !important;
  cursor: default !important;
}

@media (max-width: 800px) {
  .card-cart {
    width: 100%;
  }
}
