.card-payment {
  width: 800px;
  margin: auto;
  box-shadow: none;
}

.div-option-payment {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
  padding: 20px;
  border: 1px solid #efefef;
}

.div-row-payment {
  display: flex;
}

.div-item-row {
  display: flex;
  flex-direction: column;
  /* justify-content: flex-end; */
}

.item1-1 {
  width: 40%;
}

.item1-2 {
  width: 30%;
}

.item1-3 {
  width: 30%;
}

.row-btn-submit {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 40px 0 20px 0;
  width: 100%;
}

.btn-submit {
  width: 100px;
  border: none;
  color: white;
  background-color: black;
  padding: 10px;
  transition: all 0.2s ease-in-out;
  text-align: center;
}

.btn-submit:hover {
  background-color: #ffb201;
  color: white;
}

.div-btns-submit {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 440px;
  margin: auto;
}

.total-amount {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  margin: 0.5rem;
}
/* input:focus {
  border-color: red !important;
} */

.promo-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
}

@media (max-width: 600px) {
  .div-row-payment {
    flex-direction: column;
  }

  .item1-1 {
    width: 100%;
  }

  .item1-2 {
    width: 100%;
  }

  .item1-3 {
    width: 100%;
  }

  .row-btn-submit {
    justify-content: center;
  }

  .btn-submit {
    width: 100%;
  }

  .div-btns-submit {
    width: 100%;
  }
}
